import { languages } from '@enums/languages'
import { Storage } from './storage'
import { storageKeys } from '@enums/storageKeys'
import type { ILanguages } from '@interfaces/languages'

const exceptionsRu = [
  'az',
  'uk',
  'ua',
  'hy',
  'be',
  'ka',
  'mn',
  'kk',
  'kg',
  'uz',
  'ru',
  'tg',
  'tk',
]

export const getDefaultLanguage = (): ILanguages => {
  const lang = Storage.local.get(storageKeys.language)

  if (lang) {
    document.documentElement.lang = lang.split('-')[0]
    return lang
  }

  const language = navigator?.language

  if (language) {
    if (exceptionsRu.includes(language)) {
      document.documentElement.lang = 'ru'
      return 'ru-RU'
    }

    const findLang = languages.find(lang => lang.startsWith(language))

    if (findLang) {
      document.documentElement.lang = findLang.split('-')[0]
      return findLang
    }
  }

  document.documentElement.lang = 'en'
  return 'en-US'
}
