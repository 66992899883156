import React from 'react'
import ReactDOM from 'react-dom/client'
import i18n from 'i18n'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store'
import RoutesWrapper from 'RoutesWrapper'
import { initSentry } from '@utils/initSentry'
import { AmbientSound } from '@parts/AmbientSound'
import './styles/global.scss'
import './styles/emoji.scss'
import './styles/gifts.scss'
import './styles/chat.scss'

initSentry()

let rootElement = document.getElementById('root')

if (!rootElement) {
  rootElement = document.body
}

const root = ReactDOM.createRoot(rootElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <AmbientSound />
          <RoutesWrapper />
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)

reportWebVitals()
