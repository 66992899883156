import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
}

const userSlice = createSlice({
  name: 'user-slice',
  initialState,
  reducers: {
    updateUser(state, { payload }) {
      state.user = payload
    },
  },
})

export const { reducer: userReducer, actions: userActions } = userSlice
