export const setItemInLocalStorage = (key: string, value: any): void => {
  let stringifiedValue

  if (Array.isArray(value)) {
    stringifiedValue = value.join(',')
  } else if (typeof value === 'object') {
    stringifiedValue = JSON.stringify(value)
  } else {
    stringifiedValue = value.toString()
  }

  localStorage.setItem(key, stringifiedValue)
}

export const getItemFromLocalStorage = (key: string): string | null => {
  return localStorage.getItem(key)
}

export const deleteItemFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key)
}
