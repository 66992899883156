/* eslint-disable @typescript-eslint/promise-function-async */
import { Loader } from '@parts/Loader'
import { Suspense, lazy, memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { lazyRetry } from '@utils/lazyRetry'
import { RedirectToGeneralRout } from '@pages/redirect/RedirectToGeneralRout'
import { ROUTES } from '@enums/routes'

const Layout = lazy(() =>
  lazyRetry(() => import('components/layout'), 'Layout'),
)

const RoutesWrapper = () => {
  return (
    <Suspense fallback={<Loader animation fullscreen />}>
      <Routes>
        <Route path={ROUTES.MAIN} element={<Layout />} />
        <Route path="*" element={<RedirectToGeneralRout />} />
      </Routes>
    </Suspense>
  )
}

export default memo(RoutesWrapper)
