import { music } from '@enums/music'
import { useAppSelector } from '@store/hook'
import { memo, useEffect, useRef } from 'react'

export const AmbientSound = memo(() => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const allowControl = useRef(false)
  const isMusicAllowed = useAppSelector(
    state => state.settingsReducer.isMusicAllowed,
  )

  useEffect(() => {
    const getResume = () => {
      const context = new AudioContext()
      if (!isMusicAllowed) {
        allowControl.current = true
        window.removeEventListener('click', getResume)
        return
      }
      context
        .resume()
        .then(() => {
          if (audioRef.current) {
            void audioRef.current.play()
            allowControl.current = true
            window.removeEventListener('click', getResume)
          }
        })
        .catch(error => {
          console.debug('error play ambient music', error)
          allowControl.current = true
          window.removeEventListener('click', getResume)
        })
    }

    window.addEventListener('click', getResume)
    return () => {
      window.removeEventListener('click', getResume)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const audio = audioRef.current
    if (audio && allowControl.current) {
      if (isMusicAllowed) {
        void audio.play()
      } else {
        void audio.pause()
      }
    }
  }, [isMusicAllowed])

  return (
    <audio
      id="ambient_sound"
      ref={audioRef}
      src={music.ambient}
      preload="auto"
      {...(isMusicAllowed ? { autoPlay: true } : {})}
      loop
      controls
    />
  )
})
AmbientSound.displayName = 'AmbientSound'
