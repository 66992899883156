import { apiFalio } from '@api/index'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authDrawerReducer } from './slice/authDrawer'
import { layoutReducer } from './slice/layoutSlice'
import { roundReducer } from './slice/roundSlice'
import { settingsReducer } from './slice/settingsSlice'
import { userReducer } from './slice/userSlice'

const rootReducer = combineReducers({
  layoutReducer,
  settingsReducer,
  authDrawer: authDrawerReducer,
  userReducer,
  roundReducer,
  [apiFalio.reducerPath]: apiFalio.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiFalio.middleware,
    ),
})
