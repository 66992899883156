import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  isChatOpen: false,
  isFullScreenGame: false,
  isMobileChatOpen: false,
  isMobileRateOpen: false,
  isMobileOverlayOpen: false,
}

const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    toggleChat: state => {
      state.isChatOpen = !state.isChatOpen
      if (state.isFullScreenGame) {
        state.isFullScreenGame = false
      }
    },
    toggleFullScreenGame: state => {
      if (!state.isFullScreenGame) state.isChatOpen = false
      state.isFullScreenGame = !state.isFullScreenGame
    },
    setOpenMobileChat: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobileChatOpen = payload
    },
    setOpenMobileRate: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobileRateOpen = payload
    },
    setMobileOverlayOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobileOverlayOpen = payload
    },
    resetSettings: state => {
      state.isChatOpen = false
      state.isFullScreenGame = false
      state.isMobileChatOpen = false
      state.isMobileRateOpen = false
      state.isMobileOverlayOpen = false
    },
  },
})

export const { reducer: layoutReducer, actions: layoutActions } = layoutSlice
