import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export type IOpenAuthDrawer = 'login' | 'register' | 'forgotPassword' | null

interface InitialStateAuthDrawer {
  open: IOpenAuthDrawer
  generatedPassword: string | undefined
}

const initialState: InitialStateAuthDrawer = {
  open: null,
  generatedPassword: undefined,
}

const authDrawer = createSlice({
  name: 'authDrawer',
  initialState,
  reducers: {
    onOpen: (state, action: PayloadAction<IOpenAuthDrawer>) => {
      state.open = action.payload
    },
    onClose: state => {
      state.open = null
    },
    setGeneratedPassword: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.generatedPassword = action.payload
    },
  },
})

export const { reducer: authDrawerReducer, actions: authDrawerActions } =
  authDrawer
