export const StorageType = {
  SESSION: 'session',
  LOCAL: 'local',
}

export const getStorage = (type: string) => {
  if (type === StorageType.SESSION) {
    return window.sessionStorage
  }
  return window.localStorage
}

const setItem = (type: string) => (key: string, value: any) => {
  getStorage(type).setItem(key, JSON.stringify(value))
}

const getItem =
  (type: string) =>
  (key: string, defaultVal = '') => {
    const val = getStorage(type).getItem(key)
    if (!val || val === 'undefined') return defaultVal
    try {
      return JSON.parse(val)
    } catch (e) {
      return val
    }
  }

const removeItem = (type: string) => (key: string) => {
  getStorage(type).removeItem(key)
}

export const Storage = {
  session: {
    get: getItem(StorageType.SESSION),
    set: setItem(StorageType.SESSION),
    remove: removeItem(StorageType.SESSION),
  },
  local: {
    get: getItem(StorageType.LOCAL),
    set: setItem(StorageType.LOCAL),
    remove: removeItem(StorageType.LOCAL),
  },
}
