export const languages = [
  'en-US',
  'ru-RU',
  'de-DE',
  'uk-UA',
  'es-ES',
  'fr-FR',
  'hi-IN',
  'tr-TR',
] as const
