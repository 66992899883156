import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { settings } from '@enums/settings'
import { Storage } from '@utils/storage'
import type { IInitialState, ISetSettingAllow } from '@interfaces/settings'

const isSoundsAllowed = Storage.local.get(settings.isSoundsAllowed)
const isMusicAllowed = Storage.local.get(settings.isMusicAllowed)
const isAnimationsAllowed = Storage.local.get(settings.isAnimationsAllowed)

const initialState: IInitialState = {
  [settings.isSoundsAllowed]: isSoundsAllowed === '' ? true : isSoundsAllowed,
  [settings.isMusicAllowed]: isMusicAllowed === '' ? true : isMusicAllowed,
  [settings.isAnimationsAllowed]:
    isAnimationsAllowed === '' ? true : isAnimationsAllowed,
}

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setSettingAllow: (state, { payload }: PayloadAction<ISetSettingAllow>) => {
      const type = payload.settingType
      state[type] = payload.isAllow
      Storage.local.set(payload.settingType, payload.isAllow)
    },
  },
})

export const { reducer: settingsReducer, actions: settingsAction } =
  settingsSlice
