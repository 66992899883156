import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

type ILosers = null | 'bulls' | 'bears'
interface IInitialState {
  nextRoundTimestamp: Date
  isGameRunning: boolean
  whoLose: ILosers
}
const initialState: IInitialState = {
  nextRoundTimestamp: new Date(new Date().getTime() + 2000),
  isGameRunning: false,
  whoLose: null,
}

const roundSlice = createSlice({
  name: 'round',
  initialState,
  reducers: {
    setNexRoundTimestamp: (state, { payload }: PayloadAction<Date>) => {
      state.nextRoundTimestamp = payload
    },
    setGameRunning: (state, { payload }: PayloadAction<boolean>) => {
      state.isGameRunning = payload
    },
    setWhoLose: (state, { payload }: PayloadAction<ILosers>) => {
      state.whoLose = payload
    },
  },
})

export const { actions: roundActions, reducer: roundReducer } = roundSlice
