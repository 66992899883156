import { type store } from '@store/index'
import {
  useDispatch,
  type TypedUseSelectorHook,
  useSelector,
} from 'react-redux'

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch<AppDispatch>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
