import i18n from 'i18next'
import { getDefaultLanguage } from '@utils/locales'
import { initReactI18next } from 'react-i18next'
import de from './locales/de.json'
import en from './locales/en.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import hi from './locales/hi.json'
import ru from './locales/ru.json'
import tr from './locales/tr.json'
import ua from './locales/ua.json'

const resources = {
  'en-US': {
    translation: en,
  },
  'ru-RU': {
    translation: ru,
  },
  'de-DE': {
    translation: de,
  },
  'uk-UA': {
    translation: ua,
  },
  'es-ES': {
    translation: es,
  },
  'fr-FR': {
    translation: fr,
  },
  'hi-IN': {
    translation: hi,
  },
  'tr-TR': {
    translation: tr,
  },
}

void i18n.use(initReactI18next).init({
  fallbackLng: getDefaultLanguage(),
  debug: false,
  resources,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
