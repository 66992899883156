import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loader } from '@parts/Loader'
import { ROUTES } from '@enums/routes'

export const RedirectToGeneralRout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(ROUTES.MAIN)
  }, [navigate])

  return <Loader animation fullscreen />
}
